const throttle = (cb, interval = 100) => {
  let startTime = Date.now()
  return (...args) => {
    const now = Date.now()
    if (now > startTime + interval) {
      startTime = Date.now()
      // console.log('args', ...args)
      cb(...args)
    }
  }
}

export default throttle
