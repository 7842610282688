/*
 * @Author: ken yang
 * @Date: 2022-11-09 19:49:36
 * @LastEditTime: 2023-07-03 17:48:14
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/utils/filter/index.js
 */
import text from './text'

const st = (s, index, f = '/') => {
  if (isEmpty(s)) {
    return ''
  }
  if (!s.split(f).length) {
    return ''
  }
  return s.split(f)[index]
}
const getFixedNum = (num) => {
  try {
    if (isEmpty(num)) {
      return ''
    }
    const f = $f.st(num.toString(), 1, '.') ?? ''
    return f?.length ?? 0
  } catch {
    return ''
  }
}
const conver = (v) => {
  if (isString(v)) {
    return v
  }
  const abs = Math.abs(+v)
  const str = abs.toString()
  let f
  const isz = /e\+/.test(str)
  const isf = /e\-/.test(str)
  const isNegative = /^\-/.test(v)
  if (isz) {
    f = '+'
  } else if (isf) {
    f = '-'
  } else {
    return v
  }
  const [n, zeroNum] = str.split('e' + f)
  const nFixedNum = getFixedNum(n) || 0
  const zerosLen = f == '+' ? (zeroNum - nFixedNum) : zeroNum - 1
  const zeros = Array.from({ length: zerosLen }, () => 0).join('')
  let newValue
  if (isz) {
    newValue = `${n.toString().replace('.', '')}${zeros}`
  } else if (isf) {
    newValue = `0.${zeros}${n.toString().replace('.', '')}`
  }
  return `${isNegative ? '-' : ''}${newValue}`
}

const toFixed = (v, lan) => {
  v = conver(v)
  try {
    const [a, b] = String(v).split('.')
    const fixedNum = getFixedNum(v)
    if (a == 0 && !b && lan) {
      return `${a}.${(Array.from({ length: lan }, () => 0)).join('')}`
    } else if ((lan == 0 && a == 0) || (a == 0 && !b)) {
      return 0
    } else if (lan == 0) {
      return a
    } else if (lan == 0 || (a == 0 && b == undefined)) {
      return `${a}.${(Array.from({ length: lan }, () => 0)).join('')}`
    } else if (isEmpty(v)) {
      return null
    } else if (isEmpty(b)) {
      return `${a}.${(Array.from({ length: lan }, () => 0)).join('')}`
    } else if (lan > fixedNum) {
      const val = String(v).slice(0, a.length + 1 + lan)
      return `${val}${(Array.from({ length: lan - fixedNum }, () => 0)).join('')}`
    } else {
      return String(v).slice(0, a.length + 1 + lan)
    }
  } catch (e) {
    return ''
  }
}

const isZero = (v) => {
  const str = String(v)
  return str.split('').every(e => e == 0)
}

const numFormat = (testv = '', b) => {
  const text = conver(testv).toString().replace('-', '')
  const isNegative = /^-/.test(testv.toString())

  if (isNaN(text)) {
    return text
  }

  const integerStr = text?.toString()?.split('.')[0] ?? ''
  const fractional = text?.toString()?.split('.')[1] ?? ''
  const fractionalLen = fractional.length ?? 0

  const integerArr = []
  for (const i in integerStr) {
    const index = integerStr.length - 1 - i
    let item = integerStr[index]
    if (integerStr.length - 1 != i && integerArr.length != 0 && (integerArr.length + 1) % 3 == 0) {
      item = `,${item}`
    }
    integerArr.push(item)
  }
  const integer = integerArr.reverse().join('')
  const desimal = ((isZero(fractional) && fractionalLen) || Number(fractional)) ? `.${fractional}` : ''
  return `${isNegative ? '-' : ''}${integer}${desimal}`
}

const toFixedLimit = (v, lan) => v = getFixedNum(v) > lan ? toFixed(v, lan) : v
const numberLimit = (v, limit) => {
  const val = Number(v)
  return val > limit ? limit : val
}

const toStartUpperCase = str => {
  return str[0].toUpperCase() + str.slice(1)
}

const priceClass = v => {
  let s
  if (v == 0) {
    s = 'info'
  } else if (v >= 0) {
    s = 'buy'
  } else {
    s = 'sell'
  }
  return `c-${s}`
}

const priceTabClass = v => {
  let s
  if (v == 0) {
    s = 'info'
  } else if (v >= 0) {
    s = 'buy'
  } else {
    s = 'sell'
  }
  return `tab-${s}`
}

const pnFill = (v = 0) => {
  let d = ''
  if (v > 0) {
    d = '+'
  } else if (v < 0 && !/^-/.test(v)) {
    d = '-'
  }
  return `${d}${v}`
}
const hidePhone = (v) => {
  if (!$v.re.phone.test(v)) {
    throw new Error('非电话')
  }
  return `${v.substr(0, 3)}****${v.substr(-4)}`
}
const hideEmail = (v) => {
  if (!$v.re.email.test(v)) {
    throw new Error('非电话')
  }
  return `${v.substr(0, 2)}****@***.com`
}
const getAssetsFile = (url) => {
  return new URL(url, import.meta.url).href
}

const keepDot = (str, length) => {
  if (!str) return str
  str = str.toString()
  length = +length
  const [front, end] = str.split('.')
  if (end && end.length > length) {
    const newEnd = end.slice(0, length)
    return [front, newEnd].join('.')
  } else {
    return str
  }
}

export default {
  priceClass,
  priceTabClass,
  toFixed,
  getFixedNum,
  numFormat,
  toFixedLimit,
  numberLimit,
  toStartUpperCase,
  st,
  pnFill,
  hidePhone,
  hideEmail,
  getAssetsFile,
  keepDot
}
