/*
 * @Author: ken yang
 * @Date: 2022-09-01 15:30:02
 * @LastEditTime: 2022-09-01 15:46:19
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/directive/hideText.js
 */
let showText = ''
let hideText = ''
const fn = (el, ops) => {
  if (ops.arg) {
    el.innerText = showText
  } else {
    el.innerText = hideText
  }
}
export default {
  mounted  (el, ops) {
    showText = el.innerText
    hideText = Array.from(el.innerText).map(() => '*').join('')
    fn(el, ops)
  },
  updated (el, ops) {
    fn(el, ops)
  }
}
