import { createPinia } from 'pinia'
import useLanguageStore from '@/store/language.js'
import useUserStore from '@/store/user.js'

const pinia = createPinia()

const init = (context) => {
  context.pinia // 使用 `createPinia()` 创建的 pinia
  context.app // 使用 `createApp()` 创建的当前应用程序（仅限 Vue 3）
  context.store // 插件正在扩充的 store
  context.options // 定义存储的选项对象传递给`defineStore()`
  if (context.options.init) {
    context.options.init.call(context.store)
  }
}

pinia.use(init)

export default pinia
export { useLanguageStore, useUserStore }
