const getPlatform = () => {
  let v
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    v = 'ios'
  } else if (/(Android)/i.test(navigator.userAgent)) {
    v = 'android'
  } else {
    v = 'h5'
  }
  return v
}

export default getPlatform

