/*
 * @Author: ken yang
 * @Date: 2022-09-23 12:01:30
 * @LastEditTime: 2022-09-27 14:10:12
 * @LastEditors: ken yang
 * @Description: 防抖函数
 * @FilePath: /uexch_pc/src/utils/globalFunc/debounce.js
 */
const debounce = (fn, delay = 500) => {
  let timer = null
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}

export default debounce
