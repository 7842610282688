const localTopKey = import.meta.env.VITE_APP_IN_MODULE_TOP_KEY ? JSON.parse(import.meta.env.VITE_APP_IN_MODULE_TOP_KEY.replace(/\'/g, '"')) : []

const allModule = {
}

const inModule = (...agr) => {
  agr = agr.flatMap(e => e)
  const allModuleKey = Object.keys(allModule)

  agr.forEach(k => {
    if (allModuleKey.includes(k)) {
      allModule[k]()
    }
  })
}

const idleInModule = (m) => {
  return new Promise((resolve, reject) => {
    requestIdleCallback(async () => {
      await m()
      resolve()
    })
  })
}

const idleInModulLogic = async (key) => {
  let n = 0
  return new Promise(async (resolve, reject) => {
    key.forEach(async (k) => {
      await idleInModule(allModule[k])
      n++
      if (n == key.length) {
        resolve()
      }
    })
  })
}

const idleInModulTop = async () => {
  console.log('idleInModul - Top: start')
  const inKeys = []
  await idleInModulLogic(inKeys)
  console.log('idleInModul - Top: ' + inKeys.join(',') + '  模块加载完成')
  return inKeys
}

const idleInModulHigh = async () => {
  console.log('idleInModul - High: start')
  const inKeys = []
  await idleInModulLogic(inKeys)
  console.log('idleInModul - High: ' + inKeys.join(',') + '  模块加载完成')
  return inKeys
}

const idleInModuleList = async (opt = {}) => {
  if (!requestIdleCallback) {
    return
  }
  console.log('idleInModuleList start')
  const { delaytime = 500 } = opt
  if (import.meta.env.MODE == 'development') {
    await delay(delaytime)
  }
  await idleInModulTop()
  if (import.meta.env.MODE == 'development') {
    await delay(delaytime)
  }
  await idleInModulHigh()
  console.log('idleInModuleList end')
}

export {
  inModule,
  idleInModule,
  idleInModuleList,
}
