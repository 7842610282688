/*
 * @Author: ken yang
 * @Date: 2022-10-31 18:06:27
 * @LastEditTime: 2025-04-03 16:18:02
 * @LastEditors: ken yang
 * @Description: 活动路由
 * @FilePath: /uexch_h5/src/view/activity/index.js
 */
const RegisterSendBTC = () => import('@/view/activity/RegisterSendBTC.vue')
const NewcomerBenefits = () => import('@/view/activity/NewcomerBenefits.vue')
const Incentives = () => import('@/view/activity/Incentives.vue')
const Christmas = () => import('@/view/activity/christmas/Christmas.vue')
const CommonActivity = () => import('@/view/activity/CommonActivity.vue')
const ActivityParticipation = () => import('@/view/activity/ActivityParticipation.vue')

const routes = [
  {
    path: '/activity/registerSendBTC',
    component: RegisterSendBTC,
  },
  {
    path: '/activity/newcomerBenefits',
    component: NewcomerBenefits,
  },
  {
    path: '/activity/incentives',
    component: Incentives,
  },
  {
    path: '/christmas',
    component: Christmas,
  },
  {
    path: '/commonActivity',
    component: CommonActivity,
  },
  {
    path: '/activityParticipation',
    component: ActivityParticipation,
    props: route => ({ ...route.query }),
  },
]
const components = {}

export default {
  routes,
  components,
}
