/*
 * @Author: ken yang
 * @Date: 2022-09-03 11:18:27
 * @LastEditTime: 2023-08-28 17:24:15
 * @LastEditors: ken yang
 * @Description: c2c 路由文件
 * @FilePath: /uexch_h5/src/view/ieo/index.js
 */

// import Quotes from '@/view/quotes/Quotes.vue'
const Ieo = () => import('@/view/ieo/Ieo.vue')
const SubscriptionHistory = () => import('@/view/ieo/SubscriptionHistory.vue')
const ProjectInfo = () => import('@/view/ieo/ProjectInfo.vue')

const routes = [
  {
    path: '/launchpad',
    component: Ieo,
  },
  {
    path: '/ieo',
    component: Ieo,
  },
  {
    path: '/ieo/subscriptionHistory',
    component: SubscriptionHistory,
  },
  {
    path: '/ieo/projectInfo',
    props: route => ({ id: route.query.id }),
    component: ProjectInfo,
  },
]
const components = {}

export default {
  routes,
  components,
}
