import { createApp } from 'vue'
import Loading from './index.vue'

const relativeCls = 'g-relative'

const loading = {
  mounted (el, binding) {
    const app = createApp(Loading)
    const instance = app.mount(document.createElement('div'))
    el.instance = instance
    el.style.position = 'relative'
    if (binding.value) {
      appendEl(el)
    }
  },
  updated (el, binding) {
    if (binding.value !== binding.oldValue) {
      binding.value ? appendEl(el) : removeEl(el)
    }
  },
}

const appendEl = (el) => {
  const style = getComputedStyle(el)
  if (['absolute', 'fixed', 'relative'].indexOf(style.position) === -1) {
    addClass(el, relativeCls)
  }

  el.appendChild(el.instance.$el)
}

function removeEl (el) {
  removeClass(el, relativeCls)
  el.removeChild(el.instance.$el)
}

function addClass (el, className) {
  // 如果当前元素样式列表中没有className
  if (!el.classList.contains(className)) {
    el.classList.add(className)
  }
}

function removeClass (el, className) {
  el.classList.remove(className)
}

export default loading
