import axios from '@/utils/request'

const queryHomeUrl = '/activity/newcomer/homepage/query'
const queryHome = async (params) => {
  const { data } = await axios.post(queryHomeUrl, params)
  return data.data
}

const getWoolPartyUserUrl = '/activity/getWoolPartyUser'
const getWoolPartyUser = async (params) => {
  const { data } = await axios.post(getWoolPartyUserUrl, params)
  return data.data
}

const myRewardUrl = '/activity/newcomer/my-reward'
const myReward = async (body = {}) => {
  const { data } = await axios.post(myRewardUrl, body)
  return data.data
}

const drawPrizeUrl = '/activity/newcomer/extraction-reward-amount'
const drawPrize = async (params) => {
  const { data } = await axios.post(drawPrizeUrl, params)
  return data.data
}

const questionUrl = '/activity/newActivityGlobalConfig/question'
const question = async (params = {}) => {
  const { data } = await axios.post(questionUrl, params)
  return data.data
}

const withdrawalUrl = '/activity/newcomer/withdrawal'
const withdrawal = async (params) => {
  const { data } = await axios.post(withdrawalUrl, params)
  return data.data
}

const registerSendBTCInfoUrl = '/activity/currentActivityInfo'
const registerSendBTCInfo = async (body = {}) => {
  const { data } = await axios.post(registerSendBTCInfoUrl, body)
  return data.data
}

const registerSendBTCUserInfoUrl = '/activity/user/currentActivityInfo'
const registerSendBTCUserInfo = async (body = {}) => {
  const { data } = await axios.post(registerSendBTCUserInfoUrl, body)
  return data.data
}

const registerSendBTCSubmitUrl = '/activity/user/audit'
const registerSendBTCSubmit = async (body = {}) => {
  const { data } = await axios.post(registerSendBTCSubmitUrl, body)
  return data.data
}

const registerSendBTCReceiveUrl = '/activity/user/receiveAward'
const registerSendBTCReceive = async (body = {}) => {
  const { data } = await axios.post(registerSendBTCReceiveUrl, body)
  return data.data
}

// 新人拉新活动
const popUpOrNotUrl = '/activity/newRegisterActivity/novice/rank/popUpOrNot'
const popUpOrNot = async (params = {}) => {
  const { data } = await axios.post(popUpOrNotUrl, params)
  return data.data
}

// 手动领取奖励
const forUserUrl = '/activity/reward/forUser'
const forUser = async (params = {}) => {
  const { data } = await axios.post(forUserUrl, params)
  return data.data
}

// 拉新活动领取奖励
const newRegisterActivitySubmitUrl = '/activity/user/newRegisterActivity/submit'
const newRegisterActivitySubmit = async (params = {}) => {
  const { data } = await axios.post(newRegisterActivitySubmitUrl, params)
  return data
}

const winningRecordUrl = '/activity/winning/record'
const winningRecord = async (params = {}) => {
  const { data } = await axios.post(winningRecordUrl, params)
  return data
}

const christmasApplyUrl = '/activity/christmas/apply'
const christmasApply = async (params = {}) => {
  const { data } = await axios.post(christmasApplyUrl, params)
  return data
}

const christmasDetailUrl = '/activity/christmas/detail'
const christmasDetail = async (params = {}) => {
  const { data } = await axios.post(christmasDetailUrl, params)
  return data
}

const TimesReceiveUrl = '/activity/christmas/timesReceive'
const timesReceive = async (params = {}) => {
  const { data } = await axios.post(TimesReceiveUrl, params)
  return data
}

const awardRecordUrl = '/activity/christmas/awardRecord'
const awardRecord = async (params = {}) => {
  const { data } = await axios.post(awardRecordUrl, params)
  return data.data
}

const christmasDrawUrl = '/activity/christmas/draw'
const christmasDraw = async (params = {}) => {
  const { data } = await axios.post(christmasDrawUrl, params)
  return data.data
}

const christmasInviteRecordUrl = '/activity/christmas/inviteRecord'
const christmasInviteRecord = async (params = {}) => {
  const { data } = await axios.post(christmasInviteRecordUrl, params)
  return data.data
}

const landingPageListUrl = '/activity/landingPage/list'
const landingPageList = async (params = {}) => {
  const { data } = await axios.post(landingPageListUrl, params)
  return data.data
}

const landingPageDetailUrl = '/activity/landingPage/detail'
const landingPageDetail = async (params = {}) => {
  const { data } = await axios.post(landingPageDetailUrl, params)
  return data.data
}

const landingPageApplyUrl = '/activity/landingPage/apply'
const landingPageApply = async (params = {}) => {
  const { data } = await axios.post(landingPageApplyUrl, params)
  return data.data
}

const landingTimesReceiveUrl = '/activity/turntable/timesReceive'
const landingTimesReceive = async (params = {}) => {
  const { data } = await axios.post(landingTimesReceiveUrl, params)
  return data.data
}

const turntableDrawUrl = '/activity/turntable/draw'
const landingTurntableDraw = async (params = {}) => {
  const { data } = await axios.post(turntableDrawUrl, params)
  return data.data
}

const langdingAwardRecordUrl = '/activity/turntable/awardRecord'
const langdingAwardRecord = async (params = {}) => {
  const { data } = await axios.post(langdingAwardRecordUrl, params)
  return data.data
}

const applyPageListUrl = '/activity/applyPage/list'
const applyPageList = async (params = {}) => {
  const { data } = await axios.post(applyPageListUrl, params)
  return data.data
}

const applyPageDetailUrl = '/activity/applyPage/detail'
const applyPageDetail = async (params = {}) => {
  const { data } = await axios.post(applyPageDetailUrl, params)
  return data.data
}

const applyPageApplyUrl = '/activity/applyPage/userApply'
const applyPageApply = async (params = {}) => {
  const { data } = await axios.post(applyPageApplyUrl, params)
  return data.data
}

const needLoginUrl = [registerSendBTCUserInfoUrl, popUpOrNotUrl, forUserUrl, TimesReceiveUrl, christmasInviteRecordUrl]

export default {
  needLoginUrl,
  myReward,
  getWoolPartyUser,
  queryHome,
  drawPrize,
  question,
  withdrawal,
  registerSendBTCInfo,
  registerSendBTCUserInfo,
  registerSendBTCSubmit,
  registerSendBTCReceive,
  popUpOrNot,
  forUser,
  newRegisterActivitySubmit,
  winningRecord,
  christmasApply,
  christmasDetail,
  timesReceive,
  awardRecord,
  christmasDraw,
  christmasInviteRecord,
  landingPageList,
  landingPageDetail,
  landingPageApply,
  landingTimesReceive,
  landingTurntableDraw,
  langdingAwardRecord,
  applyPageList,
  applyPageDetail,
  applyPageApply,
}
