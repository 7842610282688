
const ChannelVerify = () => import('@/view/channelVerify/ChannelVerify.vue')

const routes = [
  {
    path: '/channelVerify',
    component: ChannelVerify,
  },
]
const components = {}

export default {
  routes,
  components,
}
