<!--
 * @Author: ken yang
 * @Date: 2023-08-03 11:20:45
 * @LastEditTime: 2023-08-04 10:49:39
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/components/widget/DiaOverlay.vue
-->
<template>
  <Column class="dia-over-lay" x="c" y="c" @click="props.close()">
    <Row class="dol-body text-center" word-break>{{ message }}</Row>
  </Column>

</template>
<script setup>

const props = defineProps({
  message: {
    default: 'text'
  },
  close: {},
  duration: {
    default: 3000
  }
})

onBeforeMount(() => {
  setTimeout(() => {
    props.close()
  }, props.duration)
})

</script>
<style lang="scss" scoped>
.dia-over-lay{
 position: fixed;
 left: 0px;
 right: 0px;
 top: 0px;
 bottom: 0px;
 z-index:199991;
 .dol-body{
   max-width: 630px;
  background: #16151ac7;
  border-radius: 8px;
  color: #fff;
  font-size: 32px;
  padding: 30px 54px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
 }
}

</style>
