/*
 * @Author: ken yang
 * @Date: 2022-08-30 11:14:14
 * @LastEditTime: 2023-07-26 15:52:29
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/store/language.js
 */
import { defineStore } from 'pinia'

const useLanguageStore = defineStore('languageStore', {
  state: () => {
    const winLocale = getUrlQuery().locale
    const browserLang = borwserLang()
    return {
      locale: winLocale || localStorage.getItem('locale') || browserLang,
      languageMap: [
        {
          label: '简体中文',
          value: LANGCN,
        },
        {
          label: 'English',
          value: LANGEN,
        },
        {
          label: '繁體中文',
          value: LANGTW,
        },
      ]
    }
  },
  getters: {
    languageName: (state) => state.languageMap.find(item => item.value == state.locale)?.label
  },
  actions: {
    async setLanguage (value) {
      this.locale = value
      localStorage.setItem('locale', value)
      $t.changeLange(value)
    },
  },
  init () {
    const HTMLDom = document.querySelector('html')
    HTMLDom.classList.add(`app-language-${this.locale}`)
    this.$subscribe((mutation, state) => {
      localStorage.theme = state.theme
      localStorage.tradeStyle = state.tradeStyle
      localStorage.locale = state.locale
    })
  },
})

export default useLanguageStore
