/*
 * @Author: ken yang
 * @Date: 2023-06-26 16:12:17
 * @LastEditTime: 2023-08-03 11:22:34
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/components/widget/DiaOverlay.js
 */
import DiaOverlay from './DiaOverlay.vue'

const DiaOverlayJs = async (data,) => {
  const mountNode = document.createElement('div')
  const { app, router } = await BiCreateApp(DiaOverlay, mountNode, {
    ...data,
    close: () => {
      data?.closed && data?.closed()

      app.unmount(mountNode)
      document.body.removeChild(mountNode)
    },
  })
  document.body.appendChild(mountNode)
}

export default DiaOverlayJs
