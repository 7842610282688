import { randomString } from './random'
import hexToRgba from './hexToRgba'
import { runTimer } from './timer'
import throttle from './throttle'
import debounce from './debounce'
import copyToClip from './copyToClip'
import { inModule, idleInModule, idleInModuleList, } from './inModule'
import { jsonStrToValue } from './json'
import getUrlQuery from './getUrlQuery'
import fixTime from './fixTime'
import getPlatform from './getPlatform'
import lang from './lang.js'
import reserveUrlQuery from './reserveUrlQuery'
import replaceParamVal from './replaceParamVal'
import borwserLang from './borwserLang.js'
import cookies from './cookies.js'
import downloadImage from './downloadImage'

import {
  checkType,
  isArray,
  isString,
  isNumber,
  isObject,
  isBoolean,
  isFunc,
  isRe,
  isJsonString,
  isEmpty,
  hasEmpty,
  isNaN,
} from './verifyType'

const fieldSet = (obj, key, value = null) => {
  if (isEmpty(obj[key])) {
    return
  }
  obj[key] = value
}

const toRouter = (path) => {
  router.push(path)
}

const scrollbottom = (dom) => {
  const scrollTop = dom.scrollTop
  const clientHeight = dom.clientHeight
  const scrollHeight = dom.scrollHeight
  if (scrollTop + clientHeight >= scrollHeight - 10) {
    return true
  } else {
    return false
  }
}

const delNullKey = data => {
  return Object.keys(data).reduce((o, k) => {
    const e = {}
    const item = data[k]
    if (!isEmpty(item)) {
      e[k] = item
    }
    if (isObject(item)) {
      e[k] = delNullKey(item)
    }
    return { ...o, ...e }
  }, {})
}

const tryCatch = async (cb) => {
  try {
    return [null, await cb()]
  } catch (err) {
    return [err, null]
  }
}

export {
  checkType,
  isArray,
  isString,
  isNumber,
  isObject,
  isBoolean,
  isFunc,
  isRe,
  isJsonString,
  isEmpty,
  isNaN,
  hasEmpty,
  randomString,
  hexToRgba,
  runTimer,
  throttle,
  debounce,
  copyToClip,
  fieldSet,
  inModule,
  idleInModule,
  idleInModuleList,
  toRouter,
  scrollbottom,
  jsonStrToValue,
  delNullKey,
  fixTime,
  getUrlQuery,
  getPlatform,
  borwserLang,
  tryCatch,
  lang,
  reserveUrlQuery,
  replaceParamVal,
  cookies,
  downloadImage,
}
