/*
 * @Author: ken yang
 * @Date: 2022-12-29 10:40:23
 * @LastEditTime: 2022-12-29 10:42:23
 * @LastEditors: ken yang
 * @Description: home 页面
 * @FilePath: /uexch_h5/src/view/home/index.js
 */
const Home = () => import('@/view/home/Home.vue')
const Upgrade = () => import('@/view/home/Upgrade.vue')
const Bft = () => import('@/view/home/Bft.vue')
const MajroShareholder = () => import('@/view/home/MajroShareholder.vue')
const NotPage = () => import('@/view/home/404.vue')

const routes = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/',
    component: Home,
  },
  {
    path: '/upgrade',
    component: Upgrade,
  },
  {
    path: '/404',
    component: NotPage,
  },
  {
    path: '/bft',
    component: Bft,
  },
  {
    path: '/majroShareholder',
    component: MajroShareholder,
  },
]
const components = {}

export default {
  routes,
  components,
}
