/*
 * @Author: ken yang
 * @Date: 2022-10-31 18:06:27
 * @LastEditTime: 2022-11-11 11:50:27
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/utils/winMes.js
 */
const ready = {}
const postChildTasks = {}
const postParent = (e, d) => {
  const o = {
    event: e,
    data: d,
  }
  const postMap = {
    h5: (d) => parent.postMessage(d, '*'),
    android: (d) => app.callNative(d),
    ios: (d) => window?.webkit?.messageHandlers.Param.postMessage(d),
  }
  if (platform == 'ios' && e == 'toTrade' && isString(d)) {
    postMap[platform](JSON.stringify({
      event: 'toTradeIOS',
      data: {
        symbol: d
      },
    }))
  }
  console.log('--postParent--', JSON.stringify(o))

  postMap[platform](JSON.stringify(o))
}

const postChild = (id, e, d) => {
  const el = document.getElementById(id)
  const o = {
    event: e,
    data: d,
  }
  const runTask = () => {
    console.log('JSON.stringify(o)', JSON.stringify(o))
    el.contentWindow.postMessage(JSON.stringify(o), '*')
  }
  if (ready[id]) {
    runTask()
  } else {
    if (!postChildTasks[id]) {
      postChildTasks[id] = []
    }
    postChildTasks[id].push(runTask)
    console.log('postChildTasks[id]', postChildTasks, id)
  }
}

const fchildLoadEvent = (data) => {
  ready[data] = true
  const tasks = postChildTasks[data] || []
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i]
    console.log('task', task)
    task()
  }
  postChildTasks[data] = []
}

const initEvent = () => {
  eventBus.on('toRouter', toRouter)
  eventBus.on('updateToken', token => {
    console.log('++token++', token)
    const userStore = useUserStore()
    userStore.token = token
    localStorage.token = token
  })
  // postParent('updateToken')
}

const emit = (event, d) => {
  const data = isJsonString(d) ? JSON.parse(d) : d
  eventBus.emit(event, data)
}

const init = (cb) => {
  const { winId } = getUrlQuery()
  initEvent()
  if (winId) {
    window.addEventListener('load', (d) => {
      postParent('fchildLoad', d.currentTarget.winId)
    }, false)
  }

  window.addEventListener('message', (mData) => {
    let d = mData.data
    // console.log('mData', mData, d)
    if (isEmpty(mData) || isEmpty(d)) {
      return
    }

    d = isJsonString(d) ? JSON.parse(d) : d
    let event
    let data
    if (isObject(d)) {
      event = d.event
      data = d.data
    } else {
      event = String(d)
    }
    if (event == 'fchildLoad') {
      fchildLoadEvent(data)
    } else {
      eventBus.emit(event, data)
    }
  }, false)
}

export default {
  postParent,
  postChild,
  init,
  emit,
}
