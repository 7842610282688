/*
 * @Author: ken yang
 * @Date: 2022-08-30 11:14:14
 * @LastEditTime: 2022-09-20 12:01:14
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/store/language.js
 */
import { defineStore } from 'pinia'

const useUserStore = defineStore('userStore', {
  state: () => {
    const winToken = getUrlQuery().token
    return {
      token: winToken || sessionStorage.token || localStorage.token || '',
      accountInfo: {},
      info: {}
    }
  },
  getters: {
  },
  actions: {
    async getInfo () {
      const info = await userApi.info({})

      this.$patch(state => {
        state.info = info
      })
    },
    async getAccountInfo () {
      const accountInfo = {
        1: await userApi.accountInfo({ type: 1 }),
        2: await userApi.accountInfo({ type: 2 }),
        5: await userApi.accountInfo({ type: 5 }),
      }

      this.$patch(state => {
        state.accountInfo = accountInfo
      })
    },
    async logout () {
      const pathname = window.location.pathname
      const path = pathname.substring(1)
      winMes.postParent('toLogin', { redirect: path })
    }
  },
  init () {
    if (this.token) {
      this.getInfo()
      this.getAccountInfo()
    }
  },
})

export default useUserStore
