<!--
 * @Author: ken yang
 * @Date: 2022-09-02 16:54:48
 * @LastEditTime: 2023-03-08 14:19:28
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/App.vue
-->
<template>
  <div class="trade-style-a" :class="cls">
    <van-config-provider>
      <router-view v-slot="{ Component }">
        <!-- <keep-alive> -->
        <Component :is="Component" class="app-router-view label-sm" />
      <!-- </keep-alive> -->
      </router-view>
    <!-- <van-notify /> -->
    </van-config-provider>
  </div>

</template>

<script setup>
const languageStore = useLanguageStore()

const cls = $computed(() => {
  return `app-language-${languageStore.locale}`
})

onMounted(() => {
  console.log('当前环境', import.meta.env)
})

watch(
  () => languageStore.locale,
  (newValue, oldValue) => {
    const HTMLDom = document.querySelector('html')

    HTMLDom.classList.add(`app-language-${newValue}`)
    HTMLDom.classList.remove(`app-language-${oldValue}`)
  }
)

// -----------将语言包导出成excel---------------
import index from '@/language/zh/index.js'
import downloadExcelLang from '@/utils/globalFunc/downloadExcelLang.js'
// downloadExcelLang(index)

onMounted(() => {
  console.log('当前环境h5', import.meta.env, location.href)
  // const htmlLoadingBoxDom = document.querySelector('#html-loading-box')
  // nextTick(() => {
  //   htmlLoadingBoxDom.innerHTML = ''
  // })
})

onBeforeMount(async () => {
})
</script>

<style lang="scss">
@use "sass:math";
@use "sass:map";
@import "@/sass/index.scss";

html,
body,
#app {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font-color;

}
</style>
