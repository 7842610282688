/*
 * @Author: ken yang
 * @Date: 2022-09-01 09:10:19
 * @LastEditTime: 2023-08-02 14:09:59
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
import activity from '@/view/activity/index.js'
import user from '@/view/user/index.js'
import help from '@/view/help/index.js'
import home from '@/view/home/index.js'
import ieo from '@/view/ieo/index.js'
import downLoad from '@/view/downLoad/index.js'
import channelVerify from '@/view/channelVerify/index.js'
import vote from '@/view/vote/index.js'
const Home = () => import('@/view/home/Home.vue')

const routerHistory = createWebHistory()

const routes = [
  ...activity.routes,
  ...user.routes,
  ...help.routes,
  ...home.routes,
  ...ieo.routes,
  ...downLoad.routes,
  ...channelVerify.routes,
  ...vote.routes,

  // 添加404路由配置，必须放在所有路由的最后
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  }
]

const router = createRouter({
  history: routerHistory,
  routes
})

router.beforeEach(async (to, from, next) => {
  // if (!from.meta.history) {
  //   console.log('11')
  //   to.meta.history = [to.path]
  // } else if (to.path == from.meta.history[from.meta.history.length - 2].path) {
  //   console.log('22')
  // } else {
  //   console.log('33')
  //   to.meta.history = [...from.meta.history, to.path]
  // }

  if (!from.meta.initSearch) {
    to.meta.initSearch = location.search.replace('?', '').split('&').map(e => {
      const item = e.split('=')
      return { [item[0]]: item[1] }
    }).reduce((o, e) => ({ ...o, ...e }), {})
  } else {
    to.meta.initSearch = from.meta.initSearch
  }
  if (!from.meta.initRouter) {
    to.meta.initRouter = to.path
  }

  // to.query = {
  //   ...to.query,
  //   ...to.meta.initSearch
  // }

  next()
})

export default router
