const wsUrl = import.meta.env.VITE_APP_WS_URL
const serveUrl = import.meta.env.VITE_APP_BASE_URL
const iosDdUrl = import.meta.env.VITE_APP_IOSDDURL
const bftLink = 'https://etherscan.io/token/0x2e9562358a7e3235f9caff0a2bead09205faa3d2'

export {
  wsUrl,
  serveUrl,
  iosDdUrl,
  bftLink,
}
