const getCssVar = (dom, v) => getComputedStyle(dom).getPropertyValue(v).trim()

let buy
let sell
let primary
let success
let warning
let error
let info
let lGrey
let cyan
const tradeStylVareMap = {
  'trade-style-a': {
    buy: '--trade-green',
    sell: '--trade-red',
  },
  'trade-style-b': {
    buy: '--trade-red',
    sell: '--trade-green',
  },
}

const $theme = {
  init () {
    const funcKeys = Object.keys(this).filter(k => k != 'init')
    funcKeys.forEach(k => this[k])
  },
  get tradeStyleVar () {
    const key = 'trade-style-a'
    return tradeStylVareMap[key]
  },
  get buy () {
    buy = buy || getCssVar(document.documentElement, this.tradeStyleVar.buy)
    return buy
  },
  get sell () {
    sell = sell || getCssVar(document.documentElement, this.tradeStyleVar.sell)
    return sell
  },
  get primary () {
    primary = primary || getCssVar(document.documentElement, '--el-color-primary')
    return primary
  },
  get success () {
    success = success || getCssVar(document.documentElement, '--el-color-success')
    return success
  },
  get warning () {
    warning = warning || getCssVar(document.documentElement, '--el-color-warning')
    return warning
  },
  get error () {
    error = error || getCssVar(document.documentElement, '--el-color-error')
    return error
  },
  get info () {
    info = info || getCssVar(document.documentElement, '--el-color-info')
    return info
  },
  get lGrey () {
    lGrey = lGrey || getCssVar(document.documentElement, '--el-color-lGrey')
    return lGrey
  },
  get cyan () {
    cyan = cyan || getCssVar(document.documentElement, '--el-color-cyan')
    return cyan
  },
}

export default $theme
