const Help = () => import('@/view/help/Help.vue')
const AboutUs = () => import('@/view/help/AboutUs.vue')
const GoDownload = () => import('@/view/help/GoDownload.vue')

const routes = [
  {
    path: '/help',
    component: Help,
    props: route => ({ name: (route.query.name) }),
  },
  {
    path: '/goDownload',
    component: GoDownload,
  },
  {
    path: '/aboutUs',
    component: AboutUs,
  },
]
const components = {}

export default {
  routes,
  components,
}
