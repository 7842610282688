/*
 * @Author: ken yang
 * @Date: 2022-12-29 11:18:17
 * @LastEditTime: 2023-01-03 11:35:28
 * @LastEditors: ken yang
 * @Description: 首页api
 * @FilePath: /uexch_h5/src/api/home.js
 */
import axios from '@/utils/request'

const bannersUrl = '/user/banners'
const bannersList = async (body = {}) => {
  const { data } = await axios.post(bannersUrl, body)
  return data.data
}

const channelVerifyUrl = '/user/channel/validate'
const channelVerify = async (body) => {
  const { data } = await axios.post(channelVerifyUrl, body, { auth: false })
  return data.data
}

const announcementsUrl = '/user/announcements'
const announcementsList = async (body = {}) => {
  const { data } = await axios.post(announcementsUrl, body)
  return data.data
}

const coinMarketsUrl = '/market/coinMarkets'
const coinMarketsList = async (body = {}) => {
  const { data } = await axios.post(coinMarketsUrl, body)
  return data.data
}

const tickersUrl = '/market/tickers'
const tickersList = async (body = {}) => {
  const { data } = await axios.post(tickersUrl, body)
  return data.data
}

const tickersOnTopUrl = '/market/tickersOnTop'
const tickersOnTop = async (body = {}) => {
  const { data } = await axios.post(tickersOnTopUrl, body)
  return data.data
}

const keyListUrl = 'user/index/apps/key-list'
const keyList = async (body = {}) => {
  const { data } = await axios.post(keyListUrl, body)
  return data.data
}

const needLoginUrl = [bannersList, announcementsList, coinMarketsList]

export default {
  needLoginUrl,
  bannersList,
  channelVerify,
  announcementsList,
  coinMarketsList,
  tickersList,
  tickersOnTop,
  keyList
}
