/*
 * @Author: ken yang
 * @Date: 2022-10-26 18:21:10
 * @LastEditTime: 2023-07-31 17:17:56
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/utils/validate/pwdStrengt.js
 */
const lengthPass = (value) => /.{8,32}/.test(value)
const hasNum = (value) => /\d+/.test(value)
const hasLcLetter = (value) => /[a-z]/.test(value)
const hasPuLetter = (value) => /[A-Z]/.test(value)
const hasLetters = (value) => /[a-zA-Z]/.test(value)
const hasSpecial = (value) =>
  /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/.test(value)

const verifyPass = (v) => {
  return [lengthPass(v), hasNum(v), hasLetters(v)].every(e => e)
}

export default { lengthPass, hasNum, hasLcLetter, hasPuLetter, hasSpecial, verifyPass }
