/*
 * @Author: ken yang
 * @Date: 2022-10-31 18:03:49
 * @LastEditTime: 2023-08-30 18:25:55
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/utils/request/index.js
 */
import axios from 'axios'
import useFixTime from './fixTime'
import useDelNullKey from './delNullKey'
import DiaOverlayJs from '../../components/widget/DiaOverlay'
// development
const baseURL = serveUrl
const instance = axios.create({
  baseURL: baseURL,
})

const okCode = 1
const needLoginCode = 50
const upgradeCode = 700

const deviceType2Url = [
  '/user/banners'
]

// 不需要弹报错的code
const noTipsCode = [18040, 18111]

useFixTime(instance)
useDelNullKey(instance)

// 添加请求拦截器
instance.interceptors.request.use(async (config) => {
  const userStore = useUserStore()
  const languageStore = useLanguageStore()
  const time = +new Date()
  // config.headers['access-control-allow-origin'] = 'origin'

  config.headers.token = userStore.token || ''
  config.headers.time = time
  config.headers.deviceType = 3
  const obj = fixTime(delNullKey(config.data || {}), '/')
  config.headers.signature = encrypt.requestSign(obj, time)
  config.headers.language = languageStore.locale

  if (deviceType2Url.includes(config.url)) {
    config.headers.deviceType = 2
  }

  return config
})

// 白名单报错
const errorDia = (data) => {
  if (!noTipsCode.includes(data.code)) {
    // showNotify({ type: 'danger', message: data.message })
    // showToast({
    //   message: data.message,
    //   duration: 2000,
    //   className: 'toast-dark'
    // })
    DiaOverlayJs({ message: data.message })
  }
}

instance.interceptors.response.use(
  (res) => {
    const { config, data } = res

    const userStore = useUserStore()

    if (data.code == needLoginCode) {
      errorDia(data)
      userStore.logout()
      return Promise.reject(data)
    } else if (data.code === upgradeCode) {
      router.push('/upgrade')
      return Promise.reject(data)
    } else if (data.code != okCode) {
      errorDia(data)
      return Promise.reject(data)
    }
    return res
  },
  async (error) => {
    const response = error.response
    const httpCode = response.status
    if (httpCode != 200) {
      showNotify({ type: 'danger', message: $t('public.networkError') })
      return Promise.reject($t('public.networkError'))
    }

    return Promise.reject(error)
  }
)

const needLoginFilter = (cb) => {
  return (...age) => {
    const userStore = useUserStore()
    const url = age[0]
    if (needLoginUrl.includes(url) && isEmpty(userStore.token)) {
      throw new Error(`url:${url} 需要登录发送，已被拦截！`)
    }
    return cb(...age)
  }
}

instance.get = needLoginFilter(instance.get)
instance.post = needLoginFilter(instance.post)
instance.put = needLoginFilter(instance.put)
instance.patch = needLoginFilter(instance.patch)
instance.delete = needLoginFilter(instance.delete)

export default instance
