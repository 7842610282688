/*
 * @Author: ken yang
 * @Date: 2022-11-14 16:57:24
 * @LastEditTime: 2023-08-02 12:39:45
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/utils/constant.js
 */
const PAGES = {
  pageNum: 1,
  pageSize: 10,
}

const UPLOAD = {
  size: 5
}

const URL_QUERY = ['winId', 'hideHeader', 'locale', 'token', 'platform']

const LANGEN = 'en_us'
const LANGCN = 'zh_cn'
const LANGTW = 'zh_tw'

const COUNTDOWN = 120
export {
  PAGES,
  UPLOAD,
  LANGEN,
  LANGCN,
  LANGTW,
  URL_QUERY,
  COUNTDOWN
}
