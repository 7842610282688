const fMap = {
  '*': (v, n) => v * n,
  '/': (v, n) => v / n,
}
const fixTime = (data, f = '*') => {
  const re = /Time$/i
  return Object.keys(data).reduce((o, k) => {
    const e = {}
    const item = data[k]
    if (re.test(k)) {
      e[k] = fMap[f](item, 1000)
    } else {
      e[k] = item
    }
    if (Number(item).toString() == 'NaN') {
      e[k] = item
    }
    if (isObject(item)) {
      e[k] = fixTime(item, f)
    }
    if (isArray(item)) {
      e[k] = item.map(it => {
        const v = isObject(it) ? fixTime(it, f) : it
        return v
      })
    }
    return { ...o, ...e }
  }, {})
}

export default fixTime
