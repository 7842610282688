const runTimer = (b, t = 1000) => {
  setTimeout(async () => {
    const n = await b()
    if (n) {
      runTimer(b, t)
    }
  }, t)
}

export { runTimer }
