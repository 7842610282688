/*
 * @Author: ken yang
 * @Date: 2023-05-07 11:52:51
 * @LastEditTime: 2023-08-21 13:43:15
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/api/user.js
 */
import axios from '@/utils/request'

// 获取返佣信息
const inviteInfoUrl = '/user/invite/info'
const inviteInfo = async (params) => {
  const { data } = await axios.post(inviteInfoUrl, params)
  return data.data
}

const loginUrl = '/user/login'
const login = async (body) => {
  const { data } = await axios.post(loginUrl, body, { auth: false })
  return data.data
}

const registeredUrl = '/user/registered'
const registered = async (body = {}) => {
  const { data } = await axios.post(registeredUrl, body)
  return data.data
}

const areaListUrl = '/user/query/country-code'
const areaList = async (body = {}) => {
  const { data } = await axios.post(areaListUrl, body)
  return data.data
}

const getVerifyCodeUrl = '/user/registered/send/verification-code'
const getVerifyCode = async (body = {}) => {
  const { data } = await axios.post(getVerifyCodeUrl, body)
  return data.data
}

const infoUrl = '/user/info'
const info = async (body) => {
  const { data } = await axios.post(infoUrl, body)
  return data.data
}

const accountInfoUrl = '/account/query/info'
const accountInfo = async (body) => {
  const { data } = await axios.post(accountInfoUrl, body)
  return data.data
}

// 以后发送验证码统一使用这个接口
const sendCorgotPasswordCodeUrl = '/user/send/verification-code'
const sendCorgotPasswordCode = async (body) => {
  const { data } = await axios.post(sendCorgotPasswordCodeUrl, {
    ...body,
  })
  return data
}

// 判断是否需要图形验证码验证接口
const kaptchaIsNeedUrl = '/user/kaptcha/is-need'
const kaptchaIsNeed = async (body = {}) => {
  const { data } = await axios.post(kaptchaIsNeedUrl, body)
  return data.data
}

const needLoginUrl = [inviteInfoUrl]

export default {
  needLoginUrl,
  inviteInfo,
  registered,
  areaList,
  getVerifyCode,
  info,
  accountInfo,
  sendCorgotPasswordCode,
  kaptchaIsNeed,
  login
}
