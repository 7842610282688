/*
 * @Author: ken yang
 * @Date: 2022-11-09 19:49:36
 * @LastEditTime: 2023-01-30 12:01:57
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/api/index.js
 */
import fileApi from './file.js'
import activityApi from './activity.js'
import userApi from './user.js'
import appApi from './app.js'
import homeApi from './home.js'
import ieoApi from './ieo.js'
import voteApi from './vote.js'
const delay = date => new Promise((resolve, reject) => { // 延迟
  setTimeout(() => resolve(), date || 2000)
})

const needLoginUrl = [
  ...fileApi.needLoginUrl,
  ...activityApi.needLoginUrl,
  ...homeApi.needLoginUrl
]

export {
  fileApi,
  activityApi,
  userApi,
  appApi,
  homeApi,
  ieoApi,
  voteApi,
  needLoginUrl,
  delay,
}
