/*
 * @Author: ken yang
 * @Date: 2023-04-27 10:39:55
 * @LastEditTime: 2023-04-27 10:40:32
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/view/downLoad/index.js
 */
const DownLoad = () => import('@/view/downLoad/DownLoad.vue')

const routes = [
  {
    path: '/downLoad',
    component: DownLoad,
  },
]
const components = {}

export default {
  routes,
  components,
}
