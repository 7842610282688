<template>
  <Flex direction="column" :justifyContent="y" :alignItems="x">
    <slot></slot>
  </Flex>
</template>

<script setup>
const props = defineProps({
  x: {
    default: 'start'
  },
  y: {
    default: 'start'
  }
})

onBeforeMount(() => {})
</script>



<style lang="scss">
.Column {
}
</style>
