/*
 * @Author: ken yang
 * @Date: 2022-09-01 09:10:19
 * @LastEditTime: 2023-08-03 11:40:41
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/main.js
 */
import * as vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { createLanguage } from '@/language'
import store from '@/store'
import 'default-passive-events'
import * as globalFunc from '@/utils/globalFunc/index.js'
import directives from '@/utils/directive/index.js'
import * as constant from '@/utils/constant.js'
import GlobalError from '@/utils/globalFunc/GlobalError.js'
import BigNumber from 'bignumber.js'
import 'amfe-flexible'
import { Notify } from 'vant'
import 'vant/es/notify/style'
import { showNotify, showToast } from 'vant'
import NP from 'number-precision'
import VueLuckyCanvas from '@lucky-canvas/vue'
import Vue3Marquee from 'vue3-marquee'

// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
// import '@/sass/index.scss'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
import 'uno.css'

// console.log('process.env.NODE_ENV', process.env)
// if (!['production', 'uat'].includes(process.env.NODE_ENV)) {
//   import('vconsole').then(Vconsole => {
//     const vConsole = new VConsole()
//   })
// }
// import VConsole from 'vconsole'
// const vConsole = new VConsole()

winMes.init()

const { winId, hideHeader, platform } = getUrlQuery()
globalThis.winId = winId
console.log('platformNavigator', navigator.userAgent)
console.log('platform', import.meta.env.VITE_PLATFORM, platform, getPlatform())
globalThis.platform = import.meta.env.VITE_PLATFORM ?? platform ?? getPlatform()
globalThis.hideHeader = isEmpty(hideHeader) ? true : jsonStrToValue(hideHeader)
globalThis.$v = $v
globalThis.Notify = Notify
globalThis.NP = NP
globalThis.showNotify = showNotify
globalThis.showToast = showToast
globalThis.BigNumber = BigNumber
window.winMes = winMes

const main = async (com, dom, options) => {
  $theme.init()
  const { $t } = await createLanguage()
  globalThis.$t = $t

  setTimeout(() => {
    document.querySelector('html').setAttribute('version', import.meta.env.VITE_APP_VERSION)
  }, 5000)
  const app = import.meta.env.VITE_APP_IS_SSR ? vue.createSSRApp(App) : vue.createApp(com ?? App, options)
  Object.keys(globalFunc).forEach(funcName => {
    app.config.globalProperties[funcName] = globalFunc[funcName]
  })
  Object.keys(constant).forEach(k => {
    app.config.globalProperties[k] = constant[k]
  })

  app.config.globalProperties.$t = $t
  app.config.globalProperties.$f = $f
  app.config.globalProperties.$v = $v
  app.config.globalProperties.router = router
  app.config.globalProperties.Notify = Notify
  app.config.globalProperties.winId = globalThis.winId
  app.config.globalProperties.hideHeader = globalThis.hideHeader
  app.config.globalProperties.$theme = $theme
  app.config.globalProperties.dayjs = dayjs
  app.config.globalProperties.winMes = winMes
  app.config.globalProperties.platform = globalThis.platform
  app.config.globalProperties.hideHeader = globalThis.hideHeader
  app.config.globalProperties.NP = NP
  app.config.globalProperties.BigNumber = BigNumber

  // app.use(VueViewer)
  app.use(directives)
  app.use(router)
  app.use(store)
  app.use(VueLuckyCanvas)
  app.use(Vue3Marquee)
  app.use(GlobalError)
  app.mount(dom ?? '#app')

  return { app, router }
}

main()

// export const createApp = async () => {
//   const { app, router } = await main()
//   return { app, router }
// }

globalThis.BiCreateApp = async (com, dom, options) => {
  const { app, router } = await main(com, dom, options)
  return { app, router }
}

