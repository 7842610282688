<template>
  <Flex direction="row" :justifyContent="x" :alignItems="y">
    <slot></slot>
  </Flex>
</template>

<script setup>
const props = defineProps({
  x: {
    default: 'start',
  },
  y: {
    default: 'center',
  },
})

onBeforeMount(() => {})
</script>



<style lang="scss">
.Row {
}
</style>
