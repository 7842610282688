/*
 * @Author: ken yang
 * @Date: 2023-05-07 11:52:51
 * @LastEditTime: 2023-07-28 16:12:58
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_h5/src/view/user/index.js
 */
const Register = () => import('@/view/user/Register.vue')
const RegisterSuccess = () => import('@/view/user/RegisterSuccess.vue')
const RuSuccess = () => import('@/view/user/RuSuccess.vue')
const RegisterUpLand = () => import('@/view/user/RegisterUpLand/RegisterUpLand.vue')

const routes = [
  {
    path: '/register',
    redirect: '/user/register',
  },
  {
    path: '/user/register',
    component: Register,
  },
  {
    path: '/user/registerSuccess',
    component: RegisterSuccess,
  },
  {
    path: '/ru',
    component: RegisterUpLand,
  },
  {
    path: '/ru/registerSuccess',
    component: RuSuccess,
  },
]
const components = {}

export default {
  routes,
  components,
}
