
const Vote = () => import('@/view/vote/Vote.vue')
const MyVote = () => import('@/view/vote/MyVote.vue')

const routes = [
  {
    path: '/vote',
    component: Vote,
  },
  {
    path: '/myVote',
    component: MyVote,
  },
]
const components = {}

export default {
  routes,
  components,
}
